<app-session-timeout-modal></app-session-timeout-modal>
<app-session-timeout-warning-modal></app-session-timeout-warning-modal>
<app-help-email-modal></app-help-email-modal>
<app-notification-modal></app-notification-modal>
<app-feedback-email-modal></app-feedback-email-modal>
<app-permissions-modal></app-permissions-modal>
<app-help-modal></app-help-modal>

<app-navbar></app-navbar>
<div id="page-container">
  <div id="content-container">
		<ls-loading-spinner *ngIf="permissionsService.loadingUserPermissions; else siteContent"></ls-loading-spinner>
    <ng-template #siteContent>
			<div *ngIf="!environment.production" class="text-center hyperion-t3"><b>DEVELOPMENT ENVIRONMENT</b></div>
			<div class="container">
				<div class="mb-3 mt-3">
					<app-error-message [error]="error"></app-error-message>
				</div>
			</div>
			<div class="mb-3">
				<app-help-toast></app-help-toast>
			</div>
			<router-outlet></router-outlet>
		</ng-template>
  </div>
  <footer id="footer">
    <div class="text-center">
      <small>
        {{ languageService.getTranslation('FOOTER.DISCLAIMER') | async }}
      </small>
      <br>
      <small>
				{{ languageService.getTranslation('FOOTER.CONTACT_DISCLAIMER') | async }}
      </small>
			<br>
      <!-- <small data-bs-toggle="modal" data-bs-target="#feedback-email-modal" class="point-hover">
				{{ languageService.getTranslation('FOOTER.FEEDBACK_EMAIL') | async }}
      </small> -->
    </div>
  </footer>
</div>
